@import "../../assets/styles/global";

.footer-wrapper {
  width: 100%;
  background: $footerBlack;
  padding: 80px 0;

  @media only screen and (max-width: $dp) {
    padding: 40px 0;
  }

  .social-logo-dp {
    display: none;

    @media only screen and (max-width: $dp) {
      display: block;
      margin-bottom: 32px;
    }
  }

  .footer-block {
    display: flex;
    justify-content: space-between;
    gap: 42px;

    @media only screen and (max-width: $dp) {
      flex-wrap: wrap;
    }


    .footer-lists {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: calc(100% / 4 - 42px);

      @media only screen and (max-width: $dp) {
        width: calc(100% / 2 - 21px);
      }
    }

    .logo-and-social {
      gap: 0;
      order: 1;

      @media only screen and (max-width: $dp) {
        order: 4;
      }

      @media only screen and (max-width: $md) {
        width: 100%;
      }

      .social-logo-1440 {
        width: 100%;
        margin-bottom: 24px;

        @media only screen and (max-width: $dp) {
          display: none;
        }
      }

      .social {
        display: flex;
        margin-bottom: 24px;

        .follow-us {
          color: $whiteMain;
          font-family: Lato, sans-serif;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }

        .social-link {
          margin-left: 24px;

          .social-svgs {
            &:hover {
              path {
                fill: $mainBlue;
              }
            }
          }
        }
      }
    }

    .footer-search {
      order: 2;

      @media only screen and (max-width: $dp) {
        order: 1;
      }
    }

    .footer-company {
      order: 3;

      @media only screen and (max-width: $dp) {
        order: 2;
      }


    }

    .footer-contact {
      order: 4;

      @media only screen and (max-width: $dp) {
        order: 3;
      }

      @media only screen and (max-width: $md) {
        width: 100%;
      }
    }

    .footer-lists-title {
      color: $whiteMain;
      font-family: Lato, sans-serif;
      font-size: 18px;
      font-weight: 800;
    }

    a {
      color: $whiteMain;
      font-family: Lato, sans-serif;
      font-size: 16px;
      font-weight: 400;
      text-decoration: none;
    }

    .footer-lists-item {
      display: flex;
      align-items: center;
      width: fit-content;

      &:hover {
        .footer-lists-item-svg {


          path {
            stroke: $mainBlue;
          }
        }
      }

      .footer-lists-item-text {
        color: $whiteMain;
        font-family: Lato, sans-serif;
        font-size: 16px;
        font-weight: 400;


      }

      .footer-lists-item-svg {
        min-width: 24px;
        min-height: 24px;
        margin-right: 16px;
        cursor: pointer;

        path {
          stroke: $whiteMain;
        }

        &:hover {
          path {
            stroke: $mainBlue;
          }
        }
      }
    }
  }

  .patent-pending_reserved {
    
    @media (max-width: $dp) {
      margin-top: 20px;
    }

    @media (max-width: $md) {
      margin: 0;
    }

    .reserved, .patent-pending {
      color: $whiteMain;
      font-family: Lato, sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      white-space: nowrap;
    }

    .patent-pending {
      color: #0892F0;
      margin-bottom: 5px;
    }
  }
}