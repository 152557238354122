@import "../../assets/styles/global";

.delete-modal-wrapper {
  min-width: 300px;
  max-width: 463px;
  display: flex;
  flex-direction: column;
  background: $whiteMain;
  border-radius: 8px;
  padding: 8px;

  .delete-modal-close-button {
    align-self: end;
    cursor: pointer;
  }

  .delete-modal-content {
    padding: 16px;
    font-family: Lato, sans-serif;

    .delete-modal-content-title {
      color: $modalTitle;
      font-size: 24px;
      font-weight: 600;
    }

    .delete-modal-content-text {
      color: $descriptionColor;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin: 12px 0 24px;
    }

    .delete-modal-content-buttons {
      display: flex;
      gap: 16px;
    }
  }
}


.simple-modal-box{
  width: 100%;
  max-width: 480px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 6px 25px 0px rgba(173, 187, 201, 0.16);
  padding: 16px;
  font-family: Lato, sans-serif;
  @media(min-width: $md) {
    padding: 32px;
  }
}

.simple-modal-title{
  color: #152E47;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 8px;
}
.simple-modal-sub-title{
  color:  #3D4751;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.simple-modal-action-buttons {
  display: flex;
  gap: 16px;
}