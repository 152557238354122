@import "../../assets/styles/global";

.terms-page {
  padding: 48px 0;

  @media (max-width: $dp) {
    padding: 40px 0;
  }

  @media (max-width: $md) {
    padding: 24px 0;
  }

  .terms-title {
    color: $termsTitle;
    font-family: Lato, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 48px;

    @media (max-width: $dp) {
      margin-bottom: 40px;
    }

    @media (max-width: $md) {
      margin-bottom: 24px;
    }
  }

  .terms-text-block {
    display: flex;
    flex-direction: column;
    background: $whiteBack;
    border-radius: 8px;
    gap: 16px;
    padding: 40px;

    .terms-text {
      color: $modalTitle;
      font-family: Lato, sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }
}