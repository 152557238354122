@import "../../../assets/styles/global";

.order-now-page {
  background: $whiteBack;
  min-height: inherit;
  height: 100%;

  .order-now-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: no-repeat url("../../../assets/images/img/orderNow/BannerOrderNow.png");
    background-size: cover;
    height: 235px;

    @media only screen and (max-width: $dp) {
      height: 147px;
    }

    @media only screen and (max-width: $md) {
      height: 109px;
    }

    .order-now-title, .order-now-text {
      color: $whiteMain;
      font-family: Lato, sans-serif;
      font-size: 48px;
      font-weight: 700;
      line-height: 72px;
      text-align: center;

      @media only screen and (max-width: $dp) {
        font-size: 32px;
        line-height: 48px;
      }

      @media only screen and (max-width: $md) {
        font-size: 24px;
        line-height: 36px;
      }
    }

    .order-now-text {
      font-size: 20px;
      font-weight: 400;

      @media only screen and (max-width: $dp) {
        font-size: 16px;
        line-height: 24px;
      }

      @media only screen and (max-width: $md) {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }

  .order-now-content {
    padding: 40px 0;
    display: flex;
    gap: 40px;

    @media only screen and (max-width: $dp) {
      max-width: 704px;
      flex-direction: column;
      gap: 24px;
      padding: 32px 0;
      margin: 0 auto;
    }
  }

  .generating-report-wrapper {
    max-width: 50%;
    padding: 40px;
    border-radius: 8px;
    background: $whiteMain;

    @media only screen and (max-width: $dp) {
      max-width: 100%;
      padding: 32px;
    }

    @media only screen and (max-width: $md) {
      padding: 16px;
    }

    .coupon-svg-error {
      path {
        stroke: transparent !important;
        fill: $errorColor;
      }
    }

    .generating-report-title {
      color: $mainBlue;
      font-family: Lato, sans-serif;
      font-size: 20px;
      font-weight: 600;
      padding-bottom: 16px;
      margin-bottom: 24px;
      border-bottom: 1px solid $borderBottom;
      @media (min-width: $md) {
        font-size: 24px;
      }
      @media (min-width: $dp) {
        font-size: 28px;
        margin-bottom: 40px;
      }
    }

    .generating-report-description {
      color: $descriptionColor;
      font-family: Lato, sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 32px;

      @media only screen and (max-width: $dp) {
        font-size: 12px;
      }
    }

    .choose-generate-report {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-top: 24px;

      .choose-generate-report-text {
        color: $descriptionColor;
        font-family: Lato, sans-serif;
        line-height: 21px
      }
    }

    .i-have-read {
      display: flex;
      align-items: center;
      gap: 8px;

      .i-have-read-text, .i-have-read-text-link {
        color: $modalTitle;
        font-family: Lato, sans-serif;
        font-size: 14px;
      }

      .i-have-read-text-link {
        margin: 0 5px;
      }
    }

    .review-your-order-text {
      margin-top: 16px;
      color: $infoText;
      font-family: Lato, sans-serif;
      line-height: 21px
    }

    .create-ac-form {
      padding: 24px;
      border-radius: 8px;
      border: 1px solid $mainBlue;
      margin-top: 40px;
      margin-bottom: 19px;

      @media only screen and (max-width: $dp) {
        margin-top: 32px;
      }

      @media only screen and (max-width: $md) {
        margin-top: 24px;
      }

      .profile-svg {
        path {
          fill: $mainBlue;
        }
      }

      .error-profile {
        path {
          stroke: transparent !important;
          fill: $errorColor;
        }
      }
    }

    .continue-button {
      margin-top: 21px;

      @media only screen and (max-width: $dp) {
        margin-top: 13px;
      }

      @media only screen and (max-width: $md) {
        margin-top: 5px;
      }
    }
  }

  .order-summary-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px;
    border-right: 8px;
    background: $whiteMain;

    @media only screen and (max-width: $dp) {
      width: 100%;
      padding: 32px;
    }

    @media only screen and (max-width: $md) {
      padding: 16px;
    }


    .order-summary-content {
      .order-summary-title {
        color: $modalTitle;
        font-size: 20px;
        font-weight: 600;
        padding-bottom: 16px;
        border-bottom: 1px solid $borderBottom;

        @media (min-width: $md) {
          font-size: 24px;
        }
        @media (min-width: $dp) {
          font-size: 32px;
        }
      }

      .order-summary-text {
        color: $descriptionColor;
        font-family: Lato, sans-serif;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        margin: 24px 0 32px;

        @media (min-width: $md) {
          font-size: 18px;
        }
        @media only screen and (max-width: $dp) {
          margin: 32px 0 16px;
        }

        @media only screen and (max-width: $dp) {
          margin: 24px 0 16px;
        }
      }

      .info_about-address_email {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 40px;
      }

      .info_about-box {
        display: flex;
        align-items: center;
        gap: 8px;

        .info_about-box_svg {
          min-width: 24px;
          min-height: 24px;
        }

        .info_about-box_description {
          color: $mainBlue;
          font-family: Lato, sans-serif;
          font-size: 14px;
          font-weight: 400;
          white-space: nowrap;
          @media (min-width: $md) {
            font-size: 16px;
          }
        }

        .info_about-box_title {
          color: $descriptionColor;
          font-family: Lato, sans-serif;
          font-size: 14px;
          font-weight: 400;
          @media (min-width: $md) {
            font-size: 16px;
          }
        }
      }

      .order-summary-report {
        border-bottom: 1px solid $borderBottom;

        .order-summary-report-header {
          display: flex;
          justify-content: space-between;
          padding-bottom: 16px;
          border-bottom: 1px solid $borderBottom;

          .order-summary-report-header-text {
            color: $modalTitle;
            font-family: Lato, sans-serif;
            font-size: 18px;
            font-weight: 500;
            @media (min-width: $md) {
              font-size: 20px;
            }
          }
        }

        .order-summary-report-info {
          display: flex;
          justify-content: space-between;
          margin: 16px 0;

          .order-summary-report-info-text {
            color: $modalTitle;
            font-family: Inter, sans-serif;
            font-weight: 500;
            line-height: 12px;
          }
        }
      }

      .coupon-wrapper {
        padding: 16px 0;
        border-bottom: 1px solid rgba(156, 156, 156, 0.30);

        .coupon-info-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 16px;

          .coupon-info-text {
            color: $modalTitle;
            font-family: Inter, sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 12px;
          }

          .coupon-info-count {
            color: #3C8A3C;
            font-family: Inter, sans-serif;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;

          }
        }

        .coupon-cards-wrapper {
          margin-top: 8px;

          .coupon-card {
            width: fit-content;
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 9px 8px;
            border-radius: 4px;
            background: #FFF4E5;

            .coupon-card-svg {
              width: 18px;
              height: 18px;

              path {
                fill: #FFCB4B;
              }
            }

            .coupon-card-text {
              color: #152E47;
              font-family: Inter, sans-serif;
              font-size: 12px;
              font-weight: 600;
              line-height: 20px;
            }

            .coupon-card-delete-svg {
              width: 18px;
              height: 18px;
              padding: 3px;
              cursor: pointer;

              path {
                fill: #1E2833;
              }
            }
          }
        }
      }
    }

    .order-summary-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;

      @media only screen and (max-width: $dp) {
        margin-top: 32px;
      }

      @media only screen and (max-width: $dp) {
        margin-top: 24px;
      }

      .order-summary-footer-left {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .order-summary-footer-left-text1 {
          color: $modalTitle;
          font-family: Lato, sans-serif;
          font-size: 18px;
          font-weight: 500;
          line-height: 27px
        }

        .order-summary-footer-left-text2 {
          color: $grayDark;
          font-family: Lato, sans-serif;
          line-height: 21px;
        }
      }

      .order-summary-footer-right-text {
        color: $mainBlue;
        font-family: Lato, sans-serif;
        font-size: 18px;
        font-weight: 700;
        //@media (min-width: $md) {
        //  padding: 32px;
        //}
      }
    }
  }
}

//Stripe
.generate-report-wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  border-right: 8px;
  background: $whiteMain;

  @media (min-width: $md) {
    padding: 32px;
  }
  @media (min-width: $dp) {
    padding: 40px;
  }
  @media only screen and (max-width: $dp) {
    width: 100%;
  }

  .generate-report-navigation {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(156, 156, 156, 0.30);
    margin-bottom: 32px;
    @media (min-width: $md) {
      margin-bottom: 40px;
    }

    .generate-report-navigation-svg {
      cursor: pointer;
    }

    .payment-details {
      color: $modalTitle;
      font-family: Lato, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      @media (min-width: $md) {
        font-size: 24px;
      }
      @media (min-width: $dp) {
        font-size: 28px;
      }
    }
  }

  .confirmation-block {
    height: 252px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    .check-svg-block {
      border-radius: 37.333px;
      border: 10.667px solid #ECFDF3;
      background: #D1FADF;
      padding: 16px;
    }

    .confirmation-text {
      color: $modalTitle;
      text-align: center;
      font-family: Lato, sans-serif;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
    }
  }
}

.checkoutForm-wrapper {

  .checkoutForm-label {
    color: $modalTitle;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    @media (min-width: $md) {
      font-size: 16px;
    }

    .checkoutForm-input {
      width: 100%;
      font-size: 16px;
      border: 1px solid $mainBlue;
      border-radius: 4px;
      padding: 12px;
      background: $whiteMain;
      margin-top: 4px;

      &:hover {
        outline: 1px solid $mainBlue;
      }

      &:active, &:focus {
        outline: 1px solid $mainBlue;
      }

      &.StripeElement--focus {
        outline: 1px solid $mainBlue
      }


    }

    .checkoutForm-input-error {
      border: 1px solid $errorColor;

      &.StripeElement--focus {
        outline: 1px solid $errorColor;
      }

      &:hover {
        outline: 1px solid $errorColor;
      }
    }
  }

  .cardholder-block, .credit-card-number,
  .credit-card-expiry-date, .credit-card-cvv {
    width: 100%;
  }

  .expiry-date-cvv {
    display: flex;
    gap: 16px;
    @media (min-width: $md) {
      gap: 24px;
    }
    @media (min-width: $dp) {
      gap: 32px;
    }
  }

  .checkbox-text-block {
    display: flex;
    align-items: center;
    gap: 8px;
    @media (min-width: $md) {
      gap: 16px;
    }

    .checkbox-text {
      display: flex;
      align-items: center;
      gap: 10px;
      color: $modalTitle;
      font-family: Lato, sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;

      @media (min-width: $md) {
        font-size: 16px;
      }

      .checkbox-text-link {
        color: $mainBlue;
        font-weight: 600;
        text-decoration-line: underline;
      }
    }
  }



  .generate-report-under-text {
    color: rgba(61, 71, 81, 0.50);
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    @media (min-width: $md) {
      font-size: 16px;
    }
  }

  .card-errors {
    color: $errorColor;
    font-size: 12px;
    font-weight: 400;
    min-height: 18px;
    margin-left: 15px;
    margin-top: 2px;
  }
}

//Confirmation
.confirmation-wrapper-box {
  padding: 40px 0;

  .confirmation-wrapper {
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    background: $whiteMain;
    border-radius: 8px;
    padding: 16px;
    margin: 0 auto;


    .confirmation-close {
      margin-left: auto;
      align-self: end;
      cursor: pointer;
    }

    .confirmation-box {
      padding: 16px;

      .confirmation-title-svg {
        display: flex;
        align-items: center;
        gap: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid $whiteBack;

        .confirmation-title {
          color: $modalTitle;
          font-family: Poppins, sans-serif;
          font-size: 22px;
          font-weight: 600;
        }
      }

      .confirmation-description {
        color: $modalTitle;
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-weight: 400;
        margin: 24px 0;
      }

      .confirmation-content {
        border-radius: 8px;
        background: $whiteBack;
        padding: 32px;

        .what-to-expect {
          color: $modalTitle;
          font-family: Poppins, sans-serif;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 24px;
        }

        .orders-processing {
          display: flex;
          flex-direction: column;
          gap: 24px;

          .confirmation-order-processing {
            color: $mainBlue;
            font-family: Poppins, sans-serif;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 8px;
          }

          .order-processing-desc {
            color: $descriptionColor;
            font-family: Poppins, sans-serif;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }

    .any-questions-box {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-top: 24px;

      .any-questions-svg {
        width: 15px;
        height: 15px;
      }

      .any-questions-text, .any-questions-mail {
        color: $modalTitle;
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-weight: 400;

      }

      .any-questions-mail {
        color: $mainBlue;
        margin-left: 5px;
        text-decoration: none;
      }
    }

    .confirmation-footer {
      color: $modalTitle;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-weight: 600;
      margin-top: 24px;
    }
  }
}


.payment-method-box{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid ;
  padding: 16px;

  .payment-method-card-title{
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  .payment-method-card-date{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 4px 0 8px;
  }
  .payment-method-card-default{
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
}

.add-new-payment-method{
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
  color:#3D4751;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.generate-report-button {
  margin: 24px 0 16px;
}