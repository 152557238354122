@import "../../assets/styles/global";

.layout-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .layout-header {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.10);
    position: fixed;
    top: 0;
    width: 100%;
    background: $whiteMain;
    z-index: 99;

    .logo-svg {
      cursor: pointer;
    }
  }

  .layout-box {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 0;


    @media only screen and (max-width: $dp) {
      padding: 19px 0;
    }

    @media only screen and (max-width: $md) {
      padding: 15px 0;
    }


    .header-right-side {


      .header-right-side-desktop {
        display: flex;
        align-items: center;

        @media only screen and (max-width: $dp) {
          display: none;
        }

        .header-navigation {
          display: flex;
          align-items: flex-end;
          .header-link {
            color: $blackMain;
            font-family: Roboto, sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
            text-decoration: none;
            margin-right: 32px;
            border-bottom: 2px solid white;

            &.active {
              border-bottom: 2px solid $mainBlue;
              color: $mainBlue;
            }
          }
        }

        .header-buttons-block {
          display: flex;
          align-items: center;
          gap: 24px;

          .header-button {
            padding: 8px 20px;
            height: 36px;
          }

          .sign-up-button {
            color: $modalTitle;
            font-family: Lato,sans-serif;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            height: 24px;
            white-space: nowrap;
            background: none;
            border: none;
            border-bottom: 1px solid black;
            padding: 0;
          }
        }

      }

      .header-right-side-mobile {
        display: none;

        @media only screen and (max-width: $dp) {
          display: block;
        }

        .header-right-side-mobile-svg {
          width: 26px;
          height: 26px;
        }

      }
    }
  }

  .main-content {
    margin-top: 80px;
    min-height: calc(100vh - 80px - 298px);

    @media only screen and (max-width: $dp) {
      margin-top: 80px;
    }

    @media only screen and (max-width: $md) {
      margin-top: 72px;
    }
  }
}

.header-menu-drawer {

  .MuiBackdrop-root {
    top: 80px;
    box-shadow: none;

    @media only screen and (max-width: $dp) {
      top: 80px;
    }

    @media only screen and (max-width: $md) {
      top: 72px;
    }
  }

  .MuiPaper-root {
    top: 80px;
    padding: 32px 0;
    width: 100%;
    box-shadow: none;

    @media only screen and (max-width: $dp) {
      top: 80px;
    }

    @media only screen and (max-width: $md) {
      top: 72px;
    }

    .menu-lists {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: calc(100% - 80px);

      .MuiListItem-root {
        padding: 0;

        .header-link {
          width: 100%;
          display: flex;
          align-items: center;
          text-decoration: none;
          color: $modalTitle;
          font-family: Lato, sans-serif;
          font-weight: 600;
          padding: 12px 32px 12px;
          //margin-bottom: 6px;
          border-bottom: 1px solid #F5F7F9;
          &:hover {
            color: $mainBlue;

            .header-link-svg {
              path {
                stroke: $mainBlue;
              }
            }
          }

          &.active {
            color: $mainBlue;

            .header-link-svg {

              path {
                stroke: $mainBlue;
              }
            }
          }

          .header-link-svg {
            width: 18px;
            height: 18px;
            margin-right: 12px;

            path {
              stroke: $modalTitle;
            }
          }
        }
      }

      .header-link-user {
        color: $mainBlue;
        width: 100%;
        display: flex;
        align-items: center;
        text-decoration: none;
        font-family: Lato, sans-serif;
        font-weight: 600;
        padding: 6px 32px;
        gap: 6px;
        cursor: pointer;
      }

      .menu-lists-button-block {
        display: flex;
        flex-direction: column;
        padding: 0 32px;
        gap: 16px;

        .menu-lists-button-sign-in {
          color: $modalTitle;
          font-family: Lato, sans-serif;
          font-weight: 600;
          border: none;
          background: none;
          text-decoration: underline;
          padding: 2px 5px;
        }

        .drawer-sign-out {
          height: 40px;
        }
      }
    }
  }
}

.loggedIn-header {
  display: flex;
  align-items: center;
  gap: 8px;
  color: $mainBlue;
  font-family: Lato, sans-serif;
  font-weight: 500;
  cursor: pointer;
}