@import "../../../assets/styles/global";

.ContactUs-page {
  height: 100%;
  min-height: inherit;
  background: $whiteBack;
  display: flex;
  justify-content: center;
  align-items: center;

  .ContactUs-wrapper {
    max-width: 504px;
    padding: 32px 40px;
    border-radius: 8px;
    background: $whiteMain;
    box-shadow: 0 6px 25px 0 rgba(173, 187, 201, 0.16);
    font-family: Lato, sans-serif;
    margin: 81px auto 81px;

    @media (max-width: $dp) {
      margin: 64px auto 64px;
      padding: 32px;
    }

    @media (max-width: $md) {
      padding: 24px 16px;
    }

    .ContactUs-title {
      color: $modalTitle;
      font-size: 28px;
      font-weight: 800;
      line-height: 40px;
    }

    .ContactUs-text {
      color: $descriptionColor;
      font-family: Lato, sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin: 8px 0 32px;

      @media (max-width: $md) {
        margin: 8px 0 24px;
      }
    }

    .contact-error-profile {
      path {
        stroke: transparent !important;
        fill: $errorColor;
      }
    }

    .contact-us-textarea {
      height: 152px;
    }

    .contact-us-send-btn {
      height: 48px;
      margin-top: 13px;

      @media (max-width: $md) {
        margin-top: 3px;
      }
    }
  }
}