@import "../../assets/styles/global";

.input-main-wrapper {
  width: 100%;
  position: relative;

  .address-custom-input-wrapper {
    position: absolute;
    width: 100%;
    border-radius: 4px;
    background: $whiteMain;
    max-height: 300px;
    overflow: auto;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    z-index: 999;

    .suggestion-item {
      color: $descriptionColor;
      font-family: Lato, sans-serif;
      font-size: 16px;
      line-height: 24px;
      padding: 7px 15px;
      cursor: pointer;
    }
  }

  .MuiInputAdornment-root {
    width: 24px;
    cursor: pointer;
    height: fit-content;
    display: flex;
    justify-content: center;


    &.error-svg {
      svg {
        path {
          stroke: $errorColor;
        }
      }
    }

    &.error-svg-password {
      svg {
        fill: $errorColor;
      }
    }
  }

  .MuiFormControl-root {
    width: 100%;

    .MuiInputBase-root {
      display: flex;
      align-items: center;
    }

    input {
      padding: 12px 16px;
    }

    input::placeholder{
      color: #667085;
    }

    .Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid $errorColor;
      }
    }
  }

  &.input-custom-wrapper {
    .MuiInputBase-root {
      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border: 2px solid $mainBlue;
        }
      }
    }

    input {
      padding: 12px 16px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid $mainBlue;
    }

    .Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid $errorColor;
      }
    }
  }

  .MuiInputAdornment-root ~ input {
    padding-left: 0;
  }

  .MuiSvgIcon-root {
    fill: $mainBlue;
  }

}

.input-main-label-header {
  color: $blackMain;
  font-family: Lato, sans-serif;
  font-weight: 600;
  margin-bottom: 4px;

  .input-main-label-header-required {
    color: $mainBlue;
  }

  &.errorLabel {
    color: $errorColor;

    .input-main-label-header-required {
      color: $errorColor;
    }

  }
}

.error-message {
  color: $errorColor;
  font-size: 12px;
  font-weight: 400;
  min-height: 18px;
  margin-left: 15px;
  margin-top: 2px
}

.helper-text {
  color: $descriptionColor;
  font-family: Lato,sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  min-height: 18px;
  margin-left: 15px;
  margin-top: 2px
}

.textarea {
  width: 100%;
  resize: none;
  padding: 12px 16px;
  border: 1px solid $mainBlue;
  color: $black;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border-radius: 4px;

  &.textarea-error {
    border: 1px solid $errorColor;

    &:hover {
      border: 1px solid $mainBlue;
      outline: 1px solid $mainBlue;
    }
  }

  &:hover {
    outline: 1px solid $mainBlue;
  }

  &:focus {
    border: 1px solid $mainBlue;
    outline: 1px solid $mainBlue;
  }

  &::placeholder {
    color: #C4C4C4;
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.checkbox-box {
  .MuiButtonBase-root {
    padding: 0;
  }
}