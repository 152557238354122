
.not-yet-box {

}


.success {
  .Toastify__progress-bar--default {
    background: #00C851;
  }
}

.error {
  .Toastify__progress-bar--default {
    background: #D72525;
  }
}

.toast-wrapper {
  display: flex;
  align-items: center;

  img {
    margin-right: 16px;
  }

  p {
    font-size: 16px;
  }
}

