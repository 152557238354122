// Project Global Style.

$blackMain: #1B1F22;
$black: #000;
$lightBlack: #2A374E;
$footerBlack: #1E2833;
$modalTitle: #152E47;
$descriptionColor: #3D4751;
$tableRowColor: #3D4C67;
$profileTile: #4B5C68;
$infoText: #A3B2BD;
$grayDark: #ACACAC;
$cardBack: #FFF4E5;
$titleLogo: #080E1C;
$termsTitle: #2B273C;
$whiteMain: #FFF;
$borderColor: #ECF1FA;
$borderColorTemp: #E9E9EB;
$backColorTemp: #EBF5EF;
$whiteBack: #F5F7F9;
$mainBlue: #347AF0;
$mainBlueDisabled: rgba(40, 108, 223, 0.50);
$hoverBtn: #286CDF;
$errorColor: #d03325;
$deleteButton: #FE385C;
$borderBottom: rgba(156, 156, 156, 0.30);


// Size Option.
$xs: 320px;
$sm: 375px;
$md: 767px;
$lg: 1024px;
$dp: 1279px;
$xl: 1440px;

.MuiModal-root {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: $xl) {
    padding: 0 32px;
  }

  @media (max-width: $dp) {
    padding: 0 32px;
  }

  @media (max-width: $md) {
    padding: 0 16px;
  }
}

#popover-my-profile {
  margin-top: 8px;

  .MuiPaper-root {
    top: 80px !important;
    right: 10px !important;
    left: auto !important;
  }
}

.container {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: $xl) {
    padding: 0 32px;
  }

  @media (max-width: $dp) {
    padding: 0 32px;
  }

  @media (max-width: $md) {
    padding: 0 16px;
  }

}

.desktop-box {
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
}

.tablet-box {
  display: none;
  @media (min-width: 768px) and (max-width: 1119px) {
    display: block;
  }
  @media (max-width: $md) {
    display: none;
  }
}

.mobile-box {
  display: none;
  @media (max-width: 767px) {
    display: block;
  }

  @media (min-width: 768px) {
    display: none;
  }
}

.ReactSwipeableBottomSheet--open {
  border-radius: 8px 8px 0 0 !important;
}

.react-swipeable-view-container {
  box-shadow: none !important;
}

// Default Style.

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-start {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.align-center {
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-all-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.full-width {
  width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}