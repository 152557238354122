@import "../../../assets/styles/global";

.home-page {
  min-height: inherit;
  height: 100%;

  .home-banner-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 366px;
    background: no-repeat right #031A56 url("../../../assets/images/img/home/BannerHome.png");
    background-size: cover;

    @media(min-width: $lg) {
      background-size: contain;
    }

    @media(min-width: $dp) {
      height: 480px;
      background-size: contain;
    }

    .home-banner-box {
      max-width: 790px;
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    .banner-title {
      color: $whiteMain;
      text-shadow: 0px 2px 2px #041A56;
      font-family: Lato,sans-serif;
      font-size: 44px;
      font-weight: 800;

      @media only screen and (max-width: $dp) {
        max-width: 568px;
        font-size: 32px;
        line-height: 48px;
      }
    }
  }

  .address-email-button {
    max-width: 962px;
    display: flex;


    @media only screen and (max-width: $dp) {
      max-width: 770px;
      margin: 0 auto;
      box-shadow: none;
      padding: 24px 32px;
      gap: 16px;
    }

    @media only screen and (max-width: $md) {
      flex-wrap: wrap;
      gap: 0;
    }

    .banner-input {
      width: 311px;

      @media only screen and (max-width: $dp) {
        width: 100%;
      }

      .MuiInputBase-root {
        background: $whiteMain;
        background: #FFF;
        border-radius: 0;

        @media only screen and (max-width: $dp) {
          border-radius: 4px;
        }

        &:hover {
          border: none;
        }

        .MuiOutlinedInput-notchedOutline {
          border-left: 1px solid;
          border-right: none;
          border-bottom: none;
          border-top: none;

          @media only screen and (max-width: $dp) {
            border-radius: 8px;
            border: 1px solid #9E9CA6;
          }
        }
      }


      &.banner-input-border-radius {
        .MuiInputBase-root {
          border: none;
          border-bottom-left-radius: 4px;
          border-top-left-radius: 4px;
          border-right: 1px solid #347AF0;
          background: #FFF;

          @media only screen and (max-width: $dp) {
            border-radius: 4px;
          }
        }
      }
    }

    .report-button {
      width: 268px;
      height: 47px;
      border-right: 4px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;

      @media only screen and (max-width: $dp) {
        border-radius: 4px;
      }

      @media only screen and (max-width: $md) {
        width: 100%;
      }
    }
  }

  //HowWeHelp
  .how-we-help {
    margin: 80px 0 128px;

    @media only screen and (max-width: $dp) {
      margin: 64px auto 80px;
      max-width: 608px;
    }

    @media only screen and (max-width: $md) {
      margin: 64px auto 64px;
    }

    .how-we-help-title {
      color: $blackMain;
      font-family: Lato, sans-serif;
      font-size: 56px;
      font-weight: 700;
      margin-bottom: 64px;

      @media only screen and (max-width: $dp) {
        text-align: start;
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 32px;
      }

      @media only screen and (max-width: $md) {
        text-align: start;
        font-size: 28px;
        margin-bottom: 40px;
      }
    }

    .cards-info {
      display: flex;
      justify-content: space-between;
      gap: 32px;

      @media only screen and (max-width: $dp) {
        flex-direction: column;
      }

      .home-card-wrapper {
        width: calc(100% / 4 - 16px);

        @media only screen and (max-width: $dp) {
          width: 100%;
        }

        .home-card-title {
          margin: 24px 0 8px;
        }
      }

    }
  }


  //work-with-us-wrapper

  .work-with-us-wrapper {
    padding: 80px 0;
    background: $whiteBack;

    @media only screen and (max-width: $dp) {
      padding: 32px 0;
    }


    .work-with-us-box {
      display: flex;
      justify-content: space-between;
      gap: 60px;

      @media only screen and (max-width: $dp) {
        max-width: 608px;
        margin: 0 auto;
        flex-direction: column;
        gap: 24px;
      }

      .work-with-u-img {
        border-radius: 20px;
        overflow: hidden;
        width: 47%;
        height: 377px;

        @media only screen and (max-width: $dp) {
          width: 100%;
          height: 300px
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .work-with-us-figcaption {
        width: 53%;

        @media only screen and (max-width: $dp) {
          width: 100%;
        }

        .work-with-us-title {
          color: #080E1C;
          font-family: Lato, sans-serif;
          font-size: 48px;
          font-weight: 700;

          @media only screen and (max-width: $dp) {
            font-size: 28px;
          }
        }

        .work-with-us-description {
          color: $descriptionColor;
          font-family: Lato, sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          margin-top: 16px;

          @media only screen and (max-width: $dp) {
            font-size: 16px;
          }
        }

        .info-counts {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 32px;
          margin-top: 40px;

          @media (max-width: $dp) {
            margin-top: 24px;
            margin-bottom: 24px;
          }


          .info-count-single {
            width: calc(50% - 40px);
            height: 84px;

            @media (max-width: $dp) {
              height: 72px;
              width: calc(50% - 20px);
            }

            .info-count {
              display: flex;
              align-items: center;
              color: $mainBlue;
              font-family: Poppins, sans-serif;
              font-size: 40px;
              font-weight: 600;
              text-transform: capitalize;
              height: 60px;

              @media only screen and (max-width: $dp) {
                font-size: 32px;
                height: 48px;
              }
            }

            .info-desc {
              color: $descriptionColor;
              font-family: Lato, sans-serif;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;


              @media only screen and (max-width: $dp) {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }

  //HOME CARD

  .home-card-wrapper {
    width: 100%;

    .home-card-title {
      color: $blackMain;
      font-family: Lato, sans-serif;
      font-size: 22px;
      font-weight: 700;
      line-height: 36px;
      white-space: normal;
      @media (min-width: $dp) {
        white-space: nowrap;
      }
    }

    .home-card-description {
      color: $descriptionColor;
      font-family: Lato, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

//SimpleSteps

.simple-steps-wrapper {
  padding-bottom: 80px;
  margin: 160px 0 128px;

  @media only screen and (max-width: $dp) {
    max-width: 608px;
    margin: 80px auto 80px;
    padding: 0;
  }

  .simple-steps-title {
    color: $titleLogo;
    font-family: Lato, sans-serif;
    font-size: 48px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 80px;

    @media only screen and (max-width: $dp) {
      font-size: 28px;
      margin-bottom: 32px;
    }
  }

  .simple-steps-box {
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 92px;

    @media only screen and (max-width: $dp) {
      flex-direction: column;
      gap: 32px;
    }

    @media only screen and (max-width: $md) {
    }

    .simple-steps-img {
      width: 576px;
      height: 545px;
      border-radius: 20px;
      overflow: hidden;

      @media only screen and (max-width: $dp) {
        width: 100%;
        height: 250px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }


    .single-steps-block {
      width: 603px;

      @media only screen and (max-width: $dp) {
        width: 100%;
        padding-left: 32px;
      }

      .single-steps-block-info {
        border-left: 1.5px dashed #9EA3A8;
      }

      .single-simple-steps {
        display: flex;
        align-items: start;
        padding-bottom: 56px;
        margin-left: -33px;

        @media only screen and (max-width: $dp) {
          padding-bottom: 40px;
        }

        &:last-child {
          padding-bottom: 72px;

          @media only screen and (max-width: $dp) {
            padding-bottom: 64px;
          }
        }

        .single-simple-steps-svg-block {
          padding: 12px;
          border-radius: 500px;
          background: #EAF1FD;
          display: flex;
          justify-content: center;
          align-items: center;

          @media (min-width: $md) {
            padding: 16px;
          }

          svg {
            path {
              fill: $mainBlue;
            }
          }
        }

        .single-simple-title-desc {
          margin-left: 32px;

          .single-simple-step {
            color: $descriptionColor;
            font-family: Lato, sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            text-transform: capitalize;

            @media only screen and (max-width: $dp) {
              font-size: 14px;
            }
          }

          .single-simple-title {
            color: $titleLogo;
            font-family: Lato, sans-serif;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
            margin: 2px 0 12px;

            @media only screen and (max-width: $dp) {
              font-size: 22px;
              margin-bottom: 8px;
            }
          }

          .single-simple-desc {
            color: $descriptionColor;
            font-family: Rambla, sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }

    .steps-button_block {
      display: flex;
      align-items: center;
      margin-top: -21px;

      .steps-button_block-line {
        width: 40px;
        border: 1px dashed #9EA3A8;
      }

      .steps-button {
        width: 243px;
        padding: 12px 24px;

        @media only screen and (max-width: $dp) {
          width: 266px;
        }
      }
    }
  }
}

//ReadyToDiscover

.ready-to-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: no-repeat url("../../../assets/images/img/home/ReadyBack.png");
  background-size: 100% 100%;
  padding-bottom: 80px;

  @media only screen and (max-width: $dp) {
    font-size: 40px;
  }

  @media only screen and (max-width: $md) {
    padding-bottom: 64px;
  }

  .ready-to-title {
    color: $blackMain;
    text-align: center;
    font-family: Lato, sans-serif;
    font-size: 80px;
    font-weight: 900;
    margin: 0 auto;
    max-width: 1180px;

    @media only screen and (max-width: $dp) {
      font-size: 40px;
      max-width: 608px;
    }
  }

  .ready-to--desc {
    max-width: 740px;
    color: $descriptionColor;
    text-align: center;
    font-family: Lato, sans-serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 35px;
    margin: 32px 0 48px;


    @media only screen and (max-width: $dp) {
      font-size: 18px;
      margin: 24px 0 40px;
    }
  }

  .ready-to-button {
    width: fit-content;
    max-width: 211px;
    margin: 0 auto;
  }
}