@import "../../../assets/styles/global";


.payment-method-wrapper {
  margin-bottom: 40px;
  border-radius: 8px;
  border: 1px solid #E4E7EC;
  background: #FFF;
  padding: 32px;
  @media (max-width: $dp) {
    padding: 24px;
  }
  @media (max-width: $md) {
    padding: 16px;
  }

  .payment-method-card-box {
    display: flex;
    width: 100%;
    max-width: 472px;
    height: 150px;
    padding: 16px;
    margin: 24px 0;
    border-radius: 8px;
    border: 1px solid #E4E7EC;
    background: #FFF;
  }

  .payment-card-number{
    color: #344054;
    font-size: 14px;
    font-weight: 500;
  }
  .payment-card-date{
    margin: 4px 0 8px;
    color: #475467;
    font-size: 14px;
    font-weight: 400;
  }
  .payment-card-default{
    color: #152E47;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .payment-method-card-info{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .edit-card-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    width: 73px;
    height: 32px;
    color: #152E47;
    font-size: 14px;
    font-weight: 500;
    gap: 4px;
  }
  .delete-card-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 90px;
    border-radius: 4px;
    border: 1px solid #FEF3F2;
    background: #FEF3F2;
    color: #B42318;
    font-size: 14px;
    font-weight: 500;
    gap: 4px;
  }
  .add-card-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;

    p {
      color: #475467;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
  }

  .we-accepted-box{
    display: flex;
    align-items: center;
    gap: 12px;
    p{
      font-family: Lato, sans-serif;
      color: #152E47;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
  }
}


.orders-page {
  padding: 80px 0;
  background: $whiteBack;
  min-height: inherit;
  height: 100%;

  @media (max-width: $dp) {
    padding: 40px 0;
  }

  @media (max-width: $md) {
    padding: 32px 0;
  }

  .loader-wrapper {
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .past-orders-wrapper {
    min-height: 286px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .past-orders-block {
      width: 100%;
      background: $whiteMain;
      border-radius: 8px;
      border: 1px solid #E4E7EC;
      background: #FFF;
      padding: 32px;

      @media (max-width: $dp) {
        padding: 24px;
      }

      @media (max-width: $md) {
        padding: 16px;
      }

      .past-orders-table {
        width: 100%;

        .past-orders-table-header {
          margin-bottom: 11px;

          .past-orders-table-header-row {
            display: flex;
            padding: 15px 0;
            align-items: center;

            .past-orders-table-cell {
              .past-orders-table-header-text {
                color: $modalTitle;
                font-family: Lato, sans-serif;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px
              }
            }
          }
        }

        .past-orders-table-body {
          display: flex;
          flex-direction: column;
          gap: 11px;
          max-height: 50vh;
          overflow: auto;
          padding: 2px;

          .past-orders-table-body-row {
            display: flex;
            padding: 14px 16px;
            border-radius: 4px;
            background: var(--White, #FFF);
            box-shadow: 0 0 6px 0 rgba(138, 138, 138, 0.24);
            align-items: center;


            .past-orders-table-cell {
              font-family: Lato, sans-serif;
              display: flex;
              gap: 5px;

              .full-width {
                color: $descriptionColor;
                font-family: Lato, sans-serif;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
              }

              .past-orders-table-body-text {
                color: $descriptionColor;
                font-family: Lato, sans-serif;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
              }

              .svg-block {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;

                &.svg-margin-left {
                  margin-left: 16px;
                }

                svg {
                  width: 100%;
                  height: 100%;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }


  .delete-account-wrapper {
    background: $whiteMain;
    padding: 32px;
    margin-top: 40px;
    border-radius: 8px;
    border: 1px solid #E4E7EC;
    background: #FFF;

    @media (max-width: $dp) {
      padding: 24px;
    }

    @media (max-width: $md) {
      padding: 16px;
      margin-top: 16px;
    }

    .delete-account-text-button {
      display: flex;
      justify-content: space-between;

      @media (max-width: $dp) {
        flex-direction: column;
      }

      .delete-account-text {
        color: $descriptionColor;
        font-family: Lato, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        max-width: 687px;
      }

      .delete-my-account {
        width: 167px;
        padding: 8px 24px;
        background: #FDECED;
        color: #FE385C;
        border: none;
        border-radius: 4px;

        @media (max-width: $dp) {
          align-self: end;
          margin-top: 16px;
        }
      }
    }
  }
}

.orders-mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.PastOrdersCard-wrapper {
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: $whiteMain;
  border-radius: 8px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.10);

  .PastOrdersCard-header {
    display: flex;
    gap: 12px;
    color: $descriptionColor;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .PastOrdersCard-text-block {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .PastOrdersCard-text {
      display: flex;
      align-items: center;
      color: #2B273C;
      font-family: Lato, sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;

      .PastOrdersCard-desc {
        color: $descriptionColor;
        font-weight: 500;
        margin-left: 8px;
      }

    }
  }

  .PastOrdersCard-svgs {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 16px;

    .svg-block {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;

      a {
        width: 100%;
        height: 100%;
      }

      svg {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }
}

.no-data-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .no-data-svg {
    width: 128px;
    height: 128px;

    @media (max-width: $dp) {
      width: 104px;
      height: 104px;
    }

    @media (max-width: $md) {
      width: 64px;
      height: 64px;
    }
  }

  .no-data-title {
    color: $modalTitle;
    font-family: Lato, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    margin: 16px 0;

    @media (max-width: $dp) {
      font-size: 20px;
    }

    @media (max-width: $md) {
      font-size: 18px;
      margin: 16px 0 8px;
    }
  }

  .no-data-text {
    color: $descriptionColor;
    text-align: center;
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;

    @media (max-width: $dp) {
      font-size: 16px;
    }

    @media (max-width: $md) {
      font-size: 14px;
    }
  }

  .no-data-button {
    width: 243px;
    margin-top: 16px;
  }
}


.orders-page-title {
  color: $modalTitle;
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 24px;
  @media (max-width: $dp) {
    margin-bottom: 16px;
    font-size: 24px;
  }
  @media (max-width: $md) {
    font-size: 16px;
  }
}

.orders-page-subtitle {
  color: #3D4751;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

}


.payment-card-input-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: Lato, sans-serif;

  input{
    border-radius: 4px;
    border: 1px solid #D0D5DD;
    height: 48px;
    width: 100%;
    padding: 0 16px;
    outline: none;
    color: #152E47;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .checkoutForm-input {
    width: 100%;
    font-size: 16px;
    border: 1px solid #D0D5DD;
    border-radius: 4px;
    padding: 16px 12px;
    background: $whiteMain;
    margin-top: 4px;
    height: 48px;
  }

  span{
    color: #152E47;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
}

.input-box{
  width: 100%;
  min-width: 100%;
  @media (min-width: $md) {
    width: 120px;
    min-width: 120px;
  }
}
.card-errors {
  color: $errorColor;
  font-size: 12px;
  font-weight: 400;
  min-height: 18px;
  margin-left: 15px;
  margin-top: 2px;
}

.payment-inputs-box{
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px 0 0;
}

.payment-input-wrapper{
  display: flex;
  flex-direction: column;
  @media (min-width: $md) {
    flex-direction: row;
  }
}