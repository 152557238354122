@import "../../assets/styles/global";

.login-wrapper {
  display: flex;
  background: $whiteMain;
  width: fit-content;
  border-radius: 12px;
  overflow: hidden;

  @media only screen and (max-width: $dp) {
    width: 463px;
  }

  @media only screen and (max-width: $md) {
    max-width: 343px;
    width: 100%;
  }

  .login-img-block {
    width: 250px;
    height: 488px;

    @media only screen and (max-width: $dp) {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .forgot-img-block {
    width: 250px;
    height: 373px;

    @media only screen and (max-width: $dp) {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .check-email-img-block {
    width: 250px;
    height: 249px;

    @media only screen and (max-width: $dp) {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .welcome-to-img-block {
    width: 250px;
    height: 338px;

    @media only screen and (max-width: $dp) {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .login-content {
    width: 463px;
    padding: 40px 32px;

    @media only screen and (max-width: $dp) {
      width: 100%;
      padding: 32px;
    }

    @media only screen and (max-width: $md) {
      padding: 24px;
    }

    .login-title {
      color: $modalTitle;
      font-family: Lato, sans-serif;
      font-size: 24px;
      font-weight: 800;
      line-height: 40px;
    }

    .forgot-desc, .check-email {
      color: $descriptionColor;
      font-family: Lato, sans-serif;
      font-weight: 500;
      margin-top: 15px;
    }

    .check-email {
      color: $mainBlue;
    }

    .login-form {
      margin-top: 32px;
    }

    .login-sign-in-button {
      width: 100%;
      margin: 14px 0 0;
      height: 42px;
    }


    .log_forgot-sign-up-btn {
      text-align: center;
      font-family: Lato, sans-serif;
      font-size: 14px;
      line-height: 24px;
      border: none;
      outline: none;
      background: none;
      padding: 0;
      opacity: .9;
    }

    .log_forgot-btn {
      color: $mainBlue;
      margin-top: 16px;
    }

    .dont-have {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16px;

      span {
        color: $lightBlack;
        text-align: center;
        font-family: Lato, sans-serif;
        font-size: 14px;
        line-height: 24px;
      }

      .sign_forgot-btn {
        color: $black;
        font-weight: 500;
        text-decoration-line: underline;
        margin-left: 3px;
      }
    }

    //welcome-content
    &.welcome-content {
      padding: 16px;
      display: flex;
      flex-direction: column;

      .close-button {
        align-self: end;
        cursor: pointer;
        transition: all .3s;

        &:hover {
          opacity: .8;
          transition: all .3s;
        }
      }

      .welcome-to-content-box {
        padding: 0;
        margin: auto 0;

        @media (min-width: 768px) {
          padding: 16px;
        }
      }

      .done-button {
        margin-top: 32px;
      }
    }
  }

  &.reset-wrapper {
    margin: 76px auto;
    border-radius: 8px;
    background: $whiteMain;
    box-shadow: 0 6px 25px 0 rgba(173, 187, 201, 0.16);

  }
}

//CREATE ACCOUNT

.create-acc-wrapper {
  width: 100%;

  .create-acc-header {
    padding: 22px 0;
    background: #FFF;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);

    .create-acc-header-logo {
      cursor: pointer;
    }
  }

  .create-acc-content {
    padding: 50px 0;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: $dp) {
      justify-content: center;
    }

    .create-account-modal {
      padding: 24px 16px;
      border-radius: 16px;
      background: #FFF;
      box-shadow: 0 6px 25px 0 rgba(173, 187, 201, 0.16);

      @media (min-width: 768px){
        padding: 32px 40px;
      }

    }

    .create-account-modal-title {
      color: $modalTitle;
      font-family: Lato, sans-serif;
      font-size: 28px;
      font-weight: 800;
      line-height: 40px;
    }

    .create-account-modal-desc {
      color: $descriptionColor;
      font-family: Lato, sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      margin: 16px 0 32px;
    }

    .create-ac-form {
      display: flex;
      flex-direction: column;

      .create-acc-button {
        height: 48px;
      }

    }

    .profile-svg {
      path {
        fill: $mainBlue;
      }
    }

    .error-profile {
      path {
        stroke: transparent !important;
        fill: $errorColor;
      }
    }

    .i-have-read {
      display: flex;
      align-items: center;
      margin: 16px 0;


      .i-have-read-checkbox {
        margin-right: 8px;
      }

      .i-have-read-text, .i-have-read-text-link {
        color: $descriptionColor;
        font-family: Lato, sans-serif;
        font-size: 14px;
        font-weight: 400;

        .i-have-read-text-link {
          margin: 0 2px;
        }
      }
    }

    .already-have-block {
      display: flex;
      align-items: center;
      margin-top: 24px;
      font-family: Lato, sans-serif;

      .already-have {
        color: $descriptionColor;
      }

      .already-have-link {
        color: $mainBlue;
        margin-left: 5px;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .create-account-unlock-exclusive {
      width: 100%;
      margin-left: 80px;
      max-width: max-content;

      @media only screen and (max-width: $dp) {
        display: none;
      }

      .unlock-exclusive-title {
        color: $modalTitle;
        font-family: Lato, sans-serif;
        font-size: 24px;
        font-weight: 800;
        line-height: 36px;
        text-transform: capitalize;
        max-width: 366px;
      }

      .unlock-exclusive-cards-wrapper {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .unlock-exclusive-card {
          background: $cardBack;
          border-radius: 8px;
          padding: 13px 24px;
          box-shadow: 0 6px 25px 0 rgba(173, 187, 201, 0.16);
          display: flex;
          align-items: center;
          gap: 8px;

          .unlock-exclusive-card-text {
            color: $modalTitle;
            font-family: Lato, sans-serif;
            font-weight: 600;
          }
        }
      }
    }
  }
}