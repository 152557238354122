@import "../../assets/styles/global";

.button-main {
  width: 100%;
  padding: 12px 24px;
  font-family: Lato, sans-serif;
  border: 1px solid $mainBlue;
  white-space: nowrap;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .2s;
  height: 48px;

  &:disabled {
    cursor: auto;
  }
}


.button-start-adornment {
  display: inline-flex;
  margin-right: 8px;
}

.button-end-adornment {
  display: inline-flex;
  margin-left: 8px;
}

.contained {
  background: $mainBlue;
  color: $whiteMain;
  transition: all .2s;

  &:hover {
    background: $hoverBtn;
    transition: all .2s;
  }

  &:disabled {
    pointer-events: none;
    background: $mainBlueDisabled;
    border: 1px solid $mainBlueDisabled;
  }
}

.outlined {
  color: $mainBlue;
  background: $whiteMain;
  transition: all .2s;

  &:hover {
    font-weight: 500;
    transition: all .2s;
    color: $hoverBtn;
    outline: 1px solid $hoverBtn;
  }
}

.cancel-button {
  border: none;
  background: $whiteBack;
  color: $descriptionColor;

  &:hover {
    background: #EBF2FD;
  }

  &:disabled {
    background: rgba(235, 242, 253, 0.50);
  }
}

.delete-button {
  background: $deleteButton;
  color: $whiteMain;
  border: 1px solid $deleteButton;

  &:hover {
    background: $errorColor;
  }

  &:disabled {
    background: $errorColor;
  }
}

.padding-0 {
  padding: 0;
  width: fit-content;
  background: transparent;
  border: none;

  &:hover {
    border: none;
    outline: none;
    background: transparent;
  }
}

.error_button {
  border: 1px solid $errorColor;
  color: $errorColor;

  &:hover {
    outline: 1px solid $errorColor;
    color: $errorColor;
  }
}