@import "../../../assets/styles/global";

.rental-page {
  min-height: inherit;
  height: 100%;
  background: $whiteBack;
  padding: 40px 0;

  @media (max-width: $lg) {
    padding: 32px 0;
  }

  @media (max-width: $md) {
    padding: 24px 0;
  }

  .content-button-block {
    display: flex;
    align-items: end;
    justify-content: end;
    gap: 24px;
    height: 800px;

    @media (max-width: $dp) {
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 0;
    }

    .rental-download-button-mobile {
      display: none;

      @media (max-width: $dp) {
        display: block;
        max-width: 375px;
        margin-top: 16px;
      }
    }

    .button-svg_block_mobile {
      position: relative;
      display: none;
      cursor: pointer;

      @media (max-width: $dp) {
        max-width: 595px;
        width: 100%;
        display: flex;
        justify-content: end;
      }

      .button-svg_mobile {
        position: absolute;
        width: 24px;
        height: 24px;
        top: -12px;
        right: 12px;
      }
    }
  }


  .rental-wrapper {
    width: 100%;
    max-width: 816px;
    height: 100%;
    overflow: scroll;

    .rental-container {
      padding: 0 64px;
      width: 100%;

      @media (max-width: $lg) {
        padding: 0 16px;
      }
    }


    .rental-screen-1 {
      width: 100%;
      background: white;


      .header-logo {
        width: 100%;
        height: 103px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        gap: 32px;

        @media (max-width: $dp) {
          margin-bottom: 24px;
        }

        @media (max-width: $lg) {
          height: 58px;
        }

        .header-logo-svg {
          width: 100%;
          height: 100%;
        }

        .header-logo-temp {
          width: 100%;
          height: 100%;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }

      .info_date-claim {
        display: flex;
        flex-direction: column;
        gap: 4px;


        .info_date {
          color: rgba(0, 0, 0, 0.97);
          font-family: Lato, sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
        }
      }

      .rental-title {
        color: rgba(0, 0, 0, 0.97);
        text-align: center;
        font-family: Lato, sans-serif;
        font-size: 28px;
        font-weight: 700;
        line-height: 33px;
        margin: 24px 0 40px;

        @media (max-width: $md) {
          font-size: 16px;
        }
      }

      .subject-property {

        .subject-property-title {
          color: rgba(0, 0, 0, 0.97);
          font-family: Lato, sans-serif;
          font-size: 18px;
          font-weight: 600;
          text-decoration-line: underline;
          margin-bottom: 16px;
        }

        .subject-property-info {
          display: flex;
          gap: 10px;

          &.margin_bottom-info {
            margin-bottom: 8px;
          }

          .subject-property-info_title {
            color: rgba(0, 0, 0, 0.97);
            font-family: Lato, sans-serif;
            font-size: 18px;
            font-weight: 400;
          }

          .subject-property-info_desc {
            color: #000;
            font-family: Lato, sans-serif;
            font-size: 16px;
            font-weight: 700;
          }

          .blue_desc {
            color: #347AF0;
            text-decoration-line: underline;
          }
        }
      }

      .active-available {
        margin-top: 52px;

        @media (max-width: $md) {
          margin-top: 24px;
        }

        .no-data-list {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #152E47;
          font-family: Lato, sans-serif;
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          min-height: 132px;
        }

        .active-available-title {
          color: rgba(0, 0, 0, 0.97);
          font-family: Lato, sans-serif;
          font-size: 18px;
          font-weight: 700;
          line-height: 27px;
          margin-bottom: 12px;

          @media (max-width: $md) {
            margin-bottom: 16px;
            font-size: 16px;
          }
        }

        .table {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 8px;
          border-radius: 4px;
          overflow: hidden;

          @media (max-width: $md) {
            display: none;
          }

          .table-body-row {
            display: flex;
            align-items: center;
            background: $backColorTemp;
            border-radius: 4px;
            overflow: hidden;


            .table-body-cell {
              width: 100px;
              color: #347AF0;
              font-family: Lato, sans-serif;
              font-size: 14px;
              font-weight: 600;
              line-height: 21px;
              padding: 12px;

              &.body-cell_no_image {
                width: 12%;
                height: 80px;
                padding: 14px 16px;
                background: #F5F7F9;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              &.body-cell_image {
                width: 80px;
                height: 80px;
                padding: 0;
                background: transparent;

                img {
                  width: 80px;
                  height: 80px;
                  min-width: 80px;
                  border-radius: 4px 0px 0px 4px;
                  object-fit: cover;
                }
              }

              &.cell-address {
                width: 26%;
              }

              &.cell-rooms {
                width: 20%;
              }

              &.cell-rent {
                width: 19%;
              }

              &.cell-dist {
                width: 24%;
              }
            }
          }

        }
      }

      .average-pricing-block {
        margin: 56px 0 49px;

        .average-pricing-title {
          color: rgba(0, 0, 0, 0.97);
          font-family: Lato, sans-serif;
          font-size: 18px;
          font-weight: 700;
          line-height: 27px;
          margin-bottom: 12px;
        }

        .average-pricing-header {
          width: 100%;
          display: flex;
          justify-content: space-between;
          background: #EFF5FF;
          border-radius: 4px;
        }

        .average-pricing-header-white {
          width: 100%;
          display: flex;
          justify-content: space-between;
          background: #FFF;
        }

        .average-pricing-header-text {
          color: rgba(0, 0, 0, 0.97);
          font-family: Lato, sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-transform: capitalize;
          padding: 8px 12px;
        }
      }

      .fair-rental-value-footer {
        color: #347AF0;
        font-family: "Open Sans", sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 27px;
        text-align: center;
        margin: 0 auto;
        padding-bottom: 40px;
      }
    }

    .rental-screen-2 {
      width: 100%;
      margin-top: 16px;
      padding: 52px 0 24px;
      background: white;

      .rental-screen-2-header-back {
        width: 100%;
        height: 150px;

        @media (max-width: $md) {
          height: 50px;
        }

        &.revers {
          transform: rotate(180deg);

          @media (max-width: $md) {
            height: 50px;
          }
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      .disclosure-block {
        display: flex;
        flex-direction: column;
        min-height: 170px;
        border-bottom: 1px solid #DADADA;
        gap: 16px;


        .disclosure-title {
          color: #000;
          font-family: Lato, sans-serif;
          font-size: 20px;
          font-weight: 700;

          @media (max-width: $md) {
            font-size: 16px;
          }
        }


        .disclosure-text {
          color: #347AF0;
          font-family: Lato, sans-serif;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;

        }
      }

      .rental-market-wrapper {
        display: flex;
        align-items: center;
        min-height: 178px;
        border-bottom: 1px solid #DADADA;
        border-top: 1px solid #DADADA;
        margin-bottom: 48px;

        .rental-market_title {
          color: #000;
          font-family: Lato, sans-serif;
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 16px;
        }

        .disclosure-box {
          list-style-type: disc;
          margin-left: 20px;

          .disclosure-list {
            color: #448960;
            font-family: Lato, sans-serif;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
          }
        }
      }

      .evaluation-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .evaluation {
          color: #000;
          font-family: Lato, sans-serif;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }

      .footer-block {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-top: 40px;
        @media (min-width: $md) {
          margin-top: 90px;
          flex-direction: row;
          gap: 60px;
        }

        .footer-social-wrapper {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          gap: 24px;

          @media (min-width: $md) {
            align-items: center;
            flex-direction: row!important;
          }
          .footer-social-box {
            display: flex;
            align-items: center;
            gap: 8px;

            a {
              color: #000;
              font-family: Lato, sans-serif;
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
              text-decoration: none;
            }
          }
        }
      }

      .expenses-wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin: 24px 0;

        @media (max-width: $md) {
          margin: 16px 0;
        }

        .expenses-wrapper-title {
          color: #000;
          font-family: Lato, sans-serif;
          font-size: 20px;
          font-weight: 700;
          line-height: 27px;
          margin-bottom: 16px;
        }

        .expenses-block {
          display: flex;
          justify-content: space-between;
          align-items: start;
          background: #EBF5EF;

          .expenses-text {
            color: #000;
            font-family: Lato, sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            padding: 8px 12px;
            width: 18%;

            @media (max-width: $md) {
              padding: 4px;
              font-family: Lato, sans-serif;
              width: 23%;
            }

            &.expenses-text_address {
              width: 46%;
              @media (max-width: $md) {
                width: 30%;
              }
            }

            &.expenses-rent {
              color: #CD303B;
            }
          }
        }
      }
    }
  }

  .button-svg-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 300px;
    width: 100%;
    height: 100%;

    @media (max-width: $dp) {
      display: none;
    }

    .button-svg {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }

    .rental-download-button {
      margin-top: auto;
      width: 149px;
      margin-left: auto;
    }
  }
}


.rental-loading-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 700px;

  .rental-loading-text {
    color: $mainBlue;
    text-align: center;
    font-family: Lato, sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 42px;
    margin: 40px 0 16px;
  }

  .rental-loading-wait {
    color: $descriptionColor;
    text-align: center;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.rental-no-data-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 700px;


  .rental-no-data-text {
    color: $modalTitle;
    font-family: Lato, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    margin: 16px 0;

    @media (max-width: $dp) {
      font-size: 20px;
    }
  }

  .rental-no-data-button {
    width: 241px;
  }
}

//Card Report

.card-report-wrapper {
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid $borderColorTemp;
  background: $backColorTemp;

  .card-report-image-address {
    display: flex;
    align-items: center;
    gap: 10px;

    .card-report_image-block {
      min-width: 40px;
      min-height: 40px;
      border-radius: 4px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }

    .card-report_address {
      color: $modalTitle;
      font-family: Lato, sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;
    }
  }

  .card-report_list-block {
    list-style-type: inherit;
    margin-left: 20px;

    .card-report_list-item {
      color: #757280;
      font-family: Lato, sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 15px;
      padding: 4px 0;
    }
  }
}

.patent-pending {
  color: #0892F0;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 600;
}


