@import "../../assets/styles/global";

.not-found-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $whiteBack;
  min-height: inherit;
  height: 100%;
  padding: 162px 0;

  .not-found-page-text {
    color: #2B273C;
    font-family: Lato, sans-serif;
    font-size: 32px;
    font-weight: 700;
    margin: 50px 0 30px;
  }

  .not-found-page-button {
    color: $mainBlue;
    font-size: 16px;
    font-weight: 500;
  }
}